<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
const { $datadogRum } = useNuxtApp();

export type Variant = 'clock-in' | 'check-in' | 'clock-out' | 'end-break';

const $emit = defineEmits<{
    (e: 'activate'): void;
}>();

const $props = withDefaults(defineProps<{
    variant: Variant;
    status: 'processing' | 'success' | null;
}>(), {});

const knob = ref<HTMLElement>();
const slider = ref<HTMLElement>();
const knobContainer = ref<HTMLElement>();

const knobLocation = ref(0);
const isDragging = ref(false);
const activationThreshold = 0.75;

const dimensions = reactive({
    knobContainerWidth: 0,
    knobContainerLeft: 0,
    knobWidth: 0,
});
const resizeObserver = new ResizeObserver(() => {
    if (!knobContainer.value?.clientWidth) {
        // Ignore when hidden from DOM
        return;
    }
    dimensions.knobContainerWidth = knobContainer.value?.clientWidth ?? 400;
    dimensions.knobContainerLeft = knobContainer.value?.getBoundingClientRect().left ?? 0;
    dimensions.knobWidth = knob.value?.clientWidth ?? 15;
});

const knobMaxValue = computed(() => dimensions.knobContainerWidth - dimensions.knobWidth);
const knobDisplayLocation = computed(() => {
    if ($props.status !== null) {
        return knobMaxValue.value;
    }
    return knobLocation.value;
});

const knobPercentage = computed(() => {
    return knobLocation.value / knobMaxValue.value;
});
const releaseBackgroundOpacity = computed(() => {
    if ($props.status !== null) {
        return 0;
    }
    return knobPercentage.value;
});
const variantTitleCase = computed(() => {
    return $props.variant.replace('(?<=^|-)[a-z]', x => x.toUpperCase());
});
const successMessage = computed(() => {
    switch ($props.variant) {
        case 'check-in':
            return 'Checked-In successfully!';
        case 'clock-in':
            return 'Clocked-In successfully!';
        case 'clock-out':
            return 'Clocked-Out successfully!';
        case 'end-break':
            return 'Break Ended successfully!';
        default:
            console.error('Unhandled case.');
            return '';
    }
});

onMounted(() => {
    resizeObserver.observe(knobContainer.value!);
    resizeObserver.observe(knob.value!);
});

onUnmounted(() => {
    resizeObserver.disconnect();
});

watch(() => $props.status, () => {
    if ($props.status === null) {
        reset();
    }
});

function onStart() {
    if ($props.status === null) {
        isDragging.value = true;
    }
}

function onMouseMove(x: MouseEvent) {
    if (isDragging.value) {
        setPosition(x.clientX);
    }
}

function onTouchMove(x: TouchEvent) {
    if (x.touches.length !== 1) {
        reset();
        return;
    }
    if (isDragging.value) {
        setPosition(x.touches[0].clientX);
    }
}

function setPosition(x: number) {
    let left = x - dimensions.knobContainerLeft - dimensions.knobWidth / 2;
    if (left < 0) {
        left = 0;
    }
    else {
        const maxLeft = knobMaxValue.value;
        left = Math.min(left, maxLeft);
    }
    knobLocation.value = left;
}

function onRelease() {
    if (knobPercentage.value > activationThreshold) {
        knobLocation.value = knobMaxValue.value;
        isDragging.value = false;
        $emit('activate');
        $datadogRum.addAction('Slider:Activated', {
            clockType: $props.variant,
            distancePercentage: knobPercentage.value * 100,
            activated: knobPercentage.value > activationThreshold,
        });
    }
    else {
        reset();
    }
}

function onEnter() {
    knobLocation.value = knobMaxValue.value;
    isDragging.value = false;
    $emit('activate');
    $datadogRum.addAction('Slider:Activated', {
        clockType: $props.variant,
        activated: true,
        keyboard: true,
    });
}

function reset() {
    if (knobLocation.value === 0 && !isDragging.value) {
        return;
    }
    $datadogRum.addAction('Slider:Reset', {
        clockType: $props.variant,
        distancePercentage: knobPercentage.value * 100,
        activated: false,
    });
    knobLocation.value = 0;
    isDragging.value = false;
}
</script>

<template>
    <div
        ref="slider"
        class="slider"
        :class="$props.variant"
        @mousemove.prevent="onMouseMove"
        @touchmove.prevent="onTouchMove"
        @mouseleave="reset"
        @mouseup="onRelease"
    >
        <div class="cover0"></div>
        <div class="cover1"></div>
        <div ref="knobContainer" class="knob-container">
            <div
                ref="knob"
                class="knob"
                role="button"
                tabindex="0"
                @mousedown.stop="onStart"
                @mouseup.stop="onRelease"
                @touchstart.stop="onStart"
                @touchend.stop="onRelease"
                @keydown.enter="onEnter"
            >
                <FontAwesomeIcon
                    v-if="$props.status === 'processing'"
                    icon="circle-notch"
                    spin
                />
                <FontAwesomeIcon v-else-if="$props.status === 'success'" icon="check" />
                <FontAwesomeIcon v-else icon="chevron-right" />
            </div>
            <div class="action-text">
                Slide to {{ variantTitleCase }}
            </div>
            <div v-if="$props.status === 'success'" class="success-text">
                {{ successMessage }}
            </div>
            <div v-else-if="knobPercentage > activationThreshold && $props.status !== 'processing'" class="release-text">
                Release
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@use "sass:color";

@import "@/assets/styles/variables";

.slider {
    --slider-padding: 0.5rem;
    --knob-dimension: 2rem;

    position: relative;
    border-radius: 64px;
    height: calc(var(--knob-dimension) + var(--slider-padding) * 2);
    padding: var(--slider-padding);
    user-select: none;
    overflow: hidden;
    width: 100%;

    .cover0,
    .cover1 {
        position: absolute;
        border-radius: 64px;
        inset: 0;
    }

    .cover0 {
        opacity: calc(1 - v-bind("releaseBackgroundOpacity"));
        background: radial-gradient(264.38% 172.2% at 9.83% -18.52%, rgba(0 0 0 / 20%) 0%, rgb(0 0 0 / 0%) 100%), var(--color);
        box-shadow: 3px 6px 10px 0 rgba(1 1 20 / 15%) inset;
    }

    .cover1 {
        opacity: v-bind("releaseBackgroundOpacity");
        background: linear-gradient(273deg, rgba(255 255 255 / 90%) 0%, rgba(255 255 255 / 70%) 100%), var(--color);
        box-shadow: 3px 6px 8px 0 rgba(1 1 20 / 2%) inset;
    }

    &.clock-in {
        --color: #{$clock-in};
    }

    &.check-in {
        --color: #{$check-in};
    }

    &.clock-out {
        --color: #{$clock-out};
    }

    &.end-break {
        --color: #{$end-break};
    }
}

.knob-container {
    position: absolute;
    inset: var(--slider-padding);
}

.knob {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: calc(v-bind("knobDisplayLocation") * 1px);
    background: #eee;
    border-radius: var(--knob-dimension);
    width: var(--knob-dimension);
    height: var(--knob-dimension);

    > svg {
        font-size: 1.1rem;
        color: var(--color);
    }

    &:focus-visible {
        box-shadow: 0 0 0 #{$btn-focus-width} color.change(#eee, $alpha: 0.5);
        outline: 0;
    }
}

.action-text,
.release-text,
.success-text {
    position: absolute;
    line-height: var(--knob-dimension);
    white-space: nowrap;
}

.action-text {
    color: var(--bs-white);
    left: calc((v-bind("knobDisplayLocation") * 1px) + var(--knob-dimension) + 2rem);
}

.release-text {
    color: var(--color);
    right: calc(var(--knob-dimension) + 2rem);
}

.success-text {
    color: var(--bs-white);
    right: calc(var(--knob-dimension) + 2rem);
}
</style>
