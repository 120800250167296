import type { Break } from '~/types/BreakTypes';

interface UserBreaksState {
    isBreakActive: boolean;
    breaks: Break[];
}

interface BreaksLocalStorage {
    breaks: Break[];
}

const localStorageKey = 'breakData';

export const userBreaks = defineStore('userBreaks', {
    state: (): UserBreaksState => {
        return {
            isBreakActive: false,
            breaks: [],
        };
    },
    actions: {
        startBreak(breakId: string): void {
            this.$state.isBreakActive = true;
            const selectedBreak = this.$state.breaks.find(b => b.id === breakId);

            if (selectedBreak) {
                selectedBreak.startTimeUtc = dayjs().utc().toDate();
                selectedBreak.status = 'started';
            }

            localStorage.setItem(localStorageKey, JSON.stringify({ breaks: this.$state.breaks }));
        },
        endBreak(breakId: string): void {
            this.$state.isBreakActive = false;
            const selectedBreak = this.$state.breaks.find(b => b.id === breakId);

            if (selectedBreak) {
                selectedBreak.endTimeUtc = dayjs().utc().toDate();
                selectedBreak.status = 'ended';
            }

            localStorage.setItem(localStorageKey, JSON.stringify({ breaks: this.$state.breaks }));
        },
        waiveBreak(breakId: string, reason: string): void {
            this.$state.isBreakActive = false;
            const selectedBreak = this.$state.breaks.find(b => b.id === breakId);

            if (selectedBreak) {
                selectedBreak.status = 'waived';
                selectedBreak.waiveReason = reason;
            }

            localStorage.setItem(localStorageKey, JSON.stringify({ breaks: this.$state.breaks }));
        },
        retrieveBreaks(): Break[] {
            const breakData: BreaksLocalStorage = JSON.parse(localStorage.getItem(localStorageKey) ?? '{}');

            // If no breaks are stored in local storage, create our test breaks
            if (!breakData.breaks) {
                const availableBreaks: Break[] = [];

                availableBreaks.push({
                    id: 'meal',
                    breakType: 'meal',
                    lengthMinutes: 30,
                    compensationType: 'unpaid',
                    endTimeUtc: null,
                    startTimeUtc: null,
                    status: null,
                    waiveReason: null,
                    projectedStartTimeUtc: dayjs().utc().toDate(),
                    projectedEndTimeUtc: dayjs().utc().toDate(),

                });

                availableBreaks.push({
                    id: 'rest',
                    breakType: 'rest',
                    lengthMinutes: 15,
                    compensationType: 'paid',
                    endTimeUtc: null,
                    startTimeUtc: null,
                    status: null,
                    waiveReason: null,
                    projectedStartTimeUtc: dayjs().utc().toDate(),
                    projectedEndTimeUtc: dayjs().utc().toDate(),
                });

                availableBreaks.push({
                    id: 'rest-test',
                    breakType: 'rest',
                    lengthMinutes: 1,
                    compensationType: 'paid',
                    endTimeUtc: null,
                    startTimeUtc: null,
                    status: null,
                    waiveReason: null,
                    projectedStartTimeUtc: dayjs().utc().toDate(),
                    projectedEndTimeUtc: dayjs().utc().toDate(),
                });

                this.$state.breaks = availableBreaks;
            }
            else {
                this.$state.breaks = breakData.breaks;
            }

            const activeBreak = this.$state.breaks.find(b => b.status === 'started');

            if (activeBreak) {
                this.$state.isBreakActive = true;
            }

            localStorage.setItem(localStorageKey, JSON.stringify({ breaks: this.$state.breaks }));
            return this.$state.breaks;
        },
    },
});
